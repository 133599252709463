import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Meta from '../shared/Meta';
import Header from './nav/Header';

// Selectors
import { getCityConfig } from '../../selectors';

class Support extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { location, cityConfig } = this.props;

    return (
      <div>
        <Meta
          title={`Support - ${cityConfig.site_title}`}
          structuredData={{
            breadcrumbs: { listItems: [
              {
                "@type": "ListItem",
                "position": 2,
                "name": "Support",
                "item": cityConfig.base_url + location.pathname,
              }
            ]}
          }}
        />
        <Header
          history={this.props.history}
          location={this.props.location}
        />
        <div className="Box Box--white u-blockCenter u-limitWidth30 u-mt2">
          <h2>Support</h2>
          <p>
            Text or call<br/>
            <a href="tel:2067990095">(206) 799-0095</a>
          </p>
          <p>
            Email us<br/>
            <a href={"mailto:" + cityConfig.support_email}>{cityConfig.support_email}</a>
          </p>
        </div>
      </div>
    );
  }
}

Support.propTypes = {
  cityConfig: PropTypes.object,
};

function mapStateToProps(state, props) {
  return {
    cityConfig: getCityConfig(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Support));
